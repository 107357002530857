import { styled } from '@egoist/vue-emotion';
import { mapGetters, mapState } from '../../../builder/node_modules/vuex';
import * as getters from '../../../builder/src/js/store/modules/getters';

let Spacing, textColor, FontFamily, textFontSize, textFontWeight;

const getTagWrapper = () => styled('div')`
    font-family:${FontFamily.DEFAULT};
    border-radius: 20px;
    background: #273D5B;
    width: 700px;
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
`

const getTagImgWrapper = () => styled('div')`
    width: 60px;
`

const getTagImg = () => styled('img')`
    margin-left: ${Spacing.SPACING_4}px;
`

const getTagLabelWrapper = () =>styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const getTagLabel = () =>styled('p')`
    margin: 0px;
    ${textColor({ themeing: "dark" }, "WHITE")}
    ${textFontSize("lg")}
    ${textFontWeight("BOLD")}
`

export const MarketplaceTag = {
    name: 'MarketplaceTag',
    props:{
        imgPath: {
            type: String,
            default: ''
        }
    },

    computed: {
        ...mapGetters('globalTheme', {
          backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
          textColor: getters.GLOBAL_STYLE_COLOR,
          textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
          textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
        }),
        ...mapState('globalTheme', {
            Spacing: ({ globalTheme }) => globalTheme.Spacing,
            FontFamily: ({ globalTheme }) => globalTheme.FontFamily,

          }),
    },
    created() {
        Spacing = this.Spacing
        textColor = this.textColor
        FontFamily = this.FontFamily
        textFontSize = this.textFontSize
        textFontWeight = this.textFontWeight
    },
    render: function(h){
        const TagWrapper = getTagWrapper()
        const TagImgWrapper = getTagImgWrapper()
        const TagImg = getTagImg()
        const TagLabelWrapper = getTagLabelWrapper()
        const TagLabel = getTagLabel()
        return(
            <TagWrapper>
                <TagImgWrapper>
                    <TagImg
                        src={this.imgPath}
                    />
                </TagImgWrapper>
                <TagLabelWrapper>
                    <TagLabel>MECLABS Certified Blueprints</TagLabel>
                </TagLabelWrapper>
            </TagWrapper>
        )
    }
}